import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import * as VueRouter from 'vue-router';
import 'bootstrap-icons/font/bootstrap-icons.css'

import OrdersPage from './pages/OrdersPage.vue'
import InvoicesPage from './pages/InvoicesPage.vue'
import OrderBevestigingenPage from './pages/OrderBevestigingenPage.vue'
import LoginPage from './pages/LoginPage.vue'
import NotFoundPage from './pages/NotFoundPage.vue'
import OrderDetailPage from './pages/OrderDetailPage.vue'
import InvoiceDetailPage from './pages/InvoiceDetailPage.vue'
import LogViewerPage from './pages/LogViewerPage.vue'
import CustomersPage from './pages/CustomersPage.vue';
import AdminPage from './pages/AdminPage.vue';
import AdminDiagnostics from './views/AdminDiagnostics.vue';
import axios from 'axios';
import localDB from './utils/localDB';
import serviceWorkerService from './services/ServiceWorkerService';

axios.interceptors.request.use(async (config) => {
  const token = await localDB.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


createApp(App)
  .use(VueRouter.createRouter({
    history: VueRouter.createWebHistory(process.env.BASE_URL),
    routes: [{
      path: '/login',
      component: LoginPage,
    }, {
      path: '/orders',
      component: OrdersPage,
    }, {
      path: '/invoices',
      component: InvoicesPage,
    }, {
      path: '/orderDetail/:id',
      name: 'OrderDetail',
      component: OrderDetailPage,
      props: true,
    }, {
      path: '/invoiceDetail/:id',
      name: 'InvoiceDetail',
      component: InvoiceDetailPage,
      props: true,
    }, {
      path: '/orderBevestigingen',
      component: OrderBevestigingenPage,
    }, {
      path: '/customers',
      name: 'Customers',
      component: CustomersPage,
      meta: { requiresAuth: true }
    }, {
      path: '/logViewer',
      component: LogViewerPage,
    }, {
      path: '/admin',
      name: 'Admin',
      component: AdminPage,
      meta: { requiresAuth: true, requiresAdmin: true }
    }, {
      path: '/admin/diagnostics',
      name: 'AdminDiagnostics',
      component: AdminDiagnostics,
      meta: { requiresAuth: true, requiresAdmin: true }
    }, {
      path: '/',
      component: LoginPage,
    }, {
      path: '/:pathMatch(.*)*',
      component: NotFoundPage,
    }]
  })).mount('#app')

// Initialiseer de service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    serviceWorkerService.initialize();
  });
}

