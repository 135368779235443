<template>
  <div class="token-diagnostics" >
    <h3>Token Diagnostics</h3>
    <div class="actions">
      <button @click="checkToken" class="btn btn-sm btn-primary">Check Token</button>
      <button @click="forceRefresh" class="btn btn-sm btn-warning">Force Refresh</button>
      <button @click="getLogs" class="btn btn-sm btn-info">Get Logs</button>
      <button @click="clearLogs" class="btn btn-sm btn-danger">Clear Logs</button>
    </div>
    
    <div v-if="tokenStatus" class="token-status">
      <h4>Token Status</h4>
      <pre>{{ JSON.stringify(tokenStatus, null, 2) }}</pre>
    </div>
    
    <div v-if="logs.length > 0" class="logs">
      <h4>Service Worker Logs</h4>
      <div class="log-controls">
        <button @click="downloadLogs" class="btn btn-sm btn-secondary">Download Logs</button>
        <input type="text" v-model="filterText" placeholder="Filter logs..." class="form-control form-control-sm">
      </div>
      <div class="log-entries">
        <div v-for="(log, index) in filteredLogs" :key="index" class="log-entry">
          <div class="log-time">{{ log.time }}</div>
          <div class="log-message">{{ log.message }}</div>
          <div v-if="log.args" class="log-args">{{ log.args }}</div>
        </div>
      </div>
    </div>
    
    <div class="mt-4">
      <h3>Problemen oplossen</h3>
      <button class="btn btn-danger" @click="clearTokens">
        Tokens wissen en opnieuw inloggen
      </button>
    </div>
    
    <div class="mt-3">
      <button class="btn btn-warning" @click="setEarlyExpiration">
        Vervaldatum instellen op 1 minuut vanaf nu
      </button>
      <button class="btn btn-primary ms-2" @click="forceRefresh2">
        Forceer token vernieuwing
      </button>
    </div>
  </div>
</template>

<script>
import localDB from '../utils/localDB';

export default {
  name: 'TokenDiagnostics',
  data() {
    return {
      tokenStatus: null,
      logs: [],
      filterText: '',
      refreshResult: null
    };
  },
  computed: {
    filteredLogs() {
      if (!this.filterText) return this.logs;
      const filter = this.filterText.toLowerCase();
      return this.logs.filter(log => 
        log.message.toLowerCase().includes(filter) || 
        (log.args && log.args.toLowerCase().includes(filter))
      );
    }
  },
  methods: {
    async checkToken() {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        const messageChannel = new MessageChannel();
        
        // Luister naar het antwoord
        messageChannel.port1.onmessage = (event) => {
          if (event.data.status) {
            this.tokenStatus = event.data.status;
          }
        };
        
        // Stuur bericht naar service worker
        navigator.serviceWorker.controller.postMessage({
          type: 'DIAGNOSTICS',
          command: 'CHECK_TOKEN'
        }, [messageChannel.port2]);
      } else {
        console.error('Service worker niet beschikbaar');
      }
    },
    async forceRefresh() {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        const messageChannel = new MessageChannel();
        
        // Luister naar het antwoord
        messageChannel.port1.onmessage = (event) => {
          this.refreshResult = event.data;
          // Controleer token status na verversing
          this.checkToken();
        };
        
        // Stuur bericht naar service worker
        navigator.serviceWorker.controller.postMessage({
          type: 'DIAGNOSTICS',
          command: 'FORCE_REFRESH'
        }, [messageChannel.port2]);
      }
    },
    forceRefresh2() {
      // Stuur een bericht naar de service worker om de token te vernieuwen
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'DIAGNOSTICS',
          command: 'FORCE_REFRESH'
        });
        alert('Opdracht verzonden om token te vernieuwen');
      } else {
        alert('Service worker niet beschikbaar');
      }
    },
    async getLogs() {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        const messageChannel = new MessageChannel();
        
        // Luister naar het antwoord
        messageChannel.port1.onmessage = (event) => {
          if (event.data.logs) {
            this.logs = event.data.logs;
          }
        };
        
        // Stuur bericht naar service worker
        navigator.serviceWorker.controller.postMessage({
          type: 'DIAGNOSTICS',
          command: 'GET_LOGS'
        }, [messageChannel.port2]);
      }
    },
    async clearLogs() {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        const messageChannel = new MessageChannel();
        
        // Luister naar het antwoord
        messageChannel.port1.onmessage = (event) => {
          if (event.data.cleared) {
            this.logs = [];
          }
        };
        
        // Stuur bericht naar service worker
        navigator.serviceWorker.controller.postMessage({
          type: 'DIAGNOSTICS',
          command: 'CLEAR_LOGS'
        }, [messageChannel.port2]);
      }
    },
    downloadLogs() {
      const logText = this.logs.map(log => `${log.time} ${log.message} ${log.args || ''}`).join('\n');
      const blob = new Blob([logText], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = url;
      a.download = `service-worker-logs-${new Date().toISOString()}.txt`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    async clearTokens() {
      // Wis alle tokens uit de lokale opslag
      await localDB.removeItem('token');
      await localDB.removeItem('refresh_token');
      await localDB.removeItem('expires_at');
      
      // Toon bevestiging
      alert('Tokens zijn gewist. Je wordt nu doorgestuurd naar de inlogpagina.');
      
      // Stuur door naar login pagina
      window.location.href = '/login';
    },
    async setEarlyExpiration() {
      // Stel de vervaldatum in op 1 minuut vanaf nu
      const expiresAt = Date.now() + (1 * 60 * 1000);
      await localDB.setItem('expires_at', expiresAt);
      alert(`Token vervaldatum ingesteld op: ${new Date(expiresAt).toLocaleString()}`);
      
      // Trigger een token check
      this.checkToken();
    }
  }
}
</script>

<style scoped>
.token-diagnostics {
  margin: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.actions {
  margin-bottom: 15px;
}

.actions button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.token-status, .logs {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: white;
}

.log-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.log-entries {
  max-height: 400px;
  overflow-y: auto;
  font-family: monospace;
  font-size: 12px;
}

.log-entry {
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.log-time {
  font-weight: bold;
  color: #666;
}

.log-args {
  color: #999;
  font-style: italic;
  margin-top: 3px;
}
</style> 