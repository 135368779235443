<template>
  <div class="container">
    <ul class="nav nav-underline"> 
      <li class="nav-item">
        <a class="nav-link link-secondary" :class="{active: (page == 'orders' || page == 'OrderDetail')}" aria-current="page"
          @click="switchPage('orders')" href="#">Orders</a>
      </li>
      <li class="nav-item">
        <a class="nav-link link-secondary" :class="{active: page == 'orderbevestigingen'}" 
          @click="switchPage('orderbevestigingen')" href="#">Orderbevestigingen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link link-secondary" :class="{active: (page == 'invoices' || page == 'InvoiceDetail')}"  
          @click="switchPage('invoices')" href="#">Facturen</a>
      </li>
      <li class="nav-item" v-if="isAdmin">
        <a class="nav-link link-secondary" :class="{active: page == 'customers'}"  
          @click="switchPage('customers')" href="#">Klanten</a>
      </li>
    </ul>
  </div>
</template>

<script>
import OAuth2AzureService from '@/services/OAuth2AzureService';

export default {
  name: "PageTabs",
  props: {
    currentPage: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ['switchPage'],
  data() {
    return {
      page: '',
      authService: null,
    } 
  },
  async created() {
    this.authService = new OAuth2AzureService();
  },
  watch: {
    currentPage(newValue) {
      if(!newValue) return;
      console.log('currentPage changed to: ', newValue)
      this.page = newValue
    }
  },
  methods: {
    switchPage(pageName) {
      this.$emit('switchPage', pageName)
      setTimeout(() => {
        this.page = pageName
      }, 200)
    }
  }
}
</script>
