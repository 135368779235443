<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Orderbevestigingen pagina</h4>
      <div class="row p-0 m-0">
        <div class="col-lg-3 col-md-4 mt-2 ">
          <FilterOrderBevestiging @filterStatusChanged="filterStatusChanged"/>
        </div>  
        <div class="col-lg-9 col-md-8 ">
          <div class="table-responsive">
            <table class="table caption-top table-striped border table-hover">
              <caption>Lijst met Orderbevestigingen</caption>
              <thead>
                <tr>
                  <th scope="col">Klant</th>
                  <th scope="col">Status</th>
                  <th scope="col">Reference</th>
                  <th scope="col">VO-nummer</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order,index) in filteredOrders" :key="index" class="clickable-row">
                  <td>{{order.customer}}</td>
                  <td><i class="bi bi-circle-fill close" :class="getColor(order.status)" ></i> {{order.status}}</td>
                  <td>{{order.reference}}</td>
                  <td>{{order.vo_number}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div ref="sentinel"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ServiceFactory from "../services/ServiceFactory" 
import FilterOrderBevestiging from "../components/FilterOrderBevestiging.vue"
import Utils from "../utils/Utils"
export default {
  name: 'OrderBevestigingenPage',
  components: {FilterOrderBevestiging},
  data() {
    return {
      orderService: null,
      orders: [],
      filteredOrders: [],
      observer: null
    }
  },
  methods: {
    getColor(status) {
      return Utils.getColorForOrderStatus(status)
    },
    filterStatusChanged(filterObj) {
      
      this.filteredOrders = this.orders.filter((order) => {
        const statusMatch = filterObj.status[this.getFilterName(order.status.toLowerCase())] || this.allFiltersUnchecked(filterObj.status);
        const customerMatch = filterObj.customer[order.customer.toLowerCase()] || this.allFiltersUnchecked(filterObj.customer);
        return statusMatch && customerMatch;
      });
      
    },
    getFilterName(name) {
      const labels = ['dubbel', 'failed', 'open', 'unit4', 'verwerkt']
      for( let i in labels) {
        const label = labels[i]
        if(name.indexOf(label) >= 0) {
          return label;
        }
      }
      return name;
    },
    allFiltersUnchecked(type) {
      for(let i in type) {
        if(type[i]) {
          return false;
        }
      }
      return true;
    },
    observeSentinel() {
      if (!this.$refs.sentinel) {
        console.log('Sentinel element niet gevonden, observer niet geïnitialiseerd');
        return;
      }

      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadMoreOrderBevestigingen(); 
          }
        });
      }, options);

      this.observer.observe(this.$refs.sentinel);
    },
    async loadMoreOrderBevestigingen() {
      // Implementeer paginering indien nodig
    }
  },
  async mounted() {
    this.orderService = ServiceFactory.getService('OrderBevestigingService')
    this.orders = await this.orderService.getOrderBevestigingen();
    this.filteredOrders = this.orders;
    
    this.$nextTick(() => {
      this.observeSentinel();
    });
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
</script>

<style scoped>
.card {
  border: none;
}
</style>
