<template>
  <div class="admin-diagnostics">
    <h1>Token Diagnostics</h1>
    <div class="card">
      <div class="card-body">
        <TokenDiagnostics />
      </div>
    </div>
  </div>
</template>

<script>
import TokenDiagnostics from '@/components/TokenDiagnostics.vue';

export default {
  name: 'AdminDiagnostics',
  components: {
    TokenDiagnostics
  }
}
</script>

<style scoped>
.admin-diagnostics {
  padding: 20px;
}
</style> 