<template>
  <div class="admin-page">
    <h1>Admin Dashboard</h1>
    
    <div class="row mt-4">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            Admin Tools
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">
                <router-link to="/logViewer">Log Viewer</router-link>
              </li>
              <li class="list-group-item">
                <router-link to="/admin/diagnostics">Token Diagnostics</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminPage',
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.admin-page {
  padding: 20px;
}
</style> 