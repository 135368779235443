<template>
  <nav class="navbar navbar-expand-lg navbar-white bg-white text-primary fixed-top shadow">
    <div class="container main d-flex justify-content-between">
      <router-link class="nav-link" to="/orders"><img src="/../img/klinger_logo.svg" class="img-fluid" style="height:3rem;" /></router-link>
      <button class="navbar-toggler" type="button" 
              @click="toggleNavbar"
              aria-controls="navbarSupportedContent" 
              aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item"><a class="nav-link" href="https://www.klinger.nl/" target="blank">Klinger.nl</a></li>
              <li class="nav-item"><a class="nav-link" href="https://openai-backend-8nss.onrender.com/" target="blank">Chat</a></li>
              
              <!-- Custom Admin dropdown menu -->
              <li class="nav-item custom-dropdown" v-if="isAdmin">
                <a class="nav-link dropdown-toggle" @click="toggleAdminMenu" ref="adminDropdownToggle">
                  Admin <span class="caret"></span>
                </a>
                <div class="custom-dropdown-menu" v-show="adminMenuOpen">
                  <a class="dropdown-item" @click="goToPage('/admin')">Admin Dashboard</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" @click="goToPage('/logViewer')">Logs</a>
                  <a class="dropdown-item" @click="testTokenRefresh">TokenRefresh</a>
                  <a class="dropdown-item" @click="checkTokenStatus">Check Token</a>
                </div>
              </li>
              
              <li class="nav-item"><a class="nav-link" @click="logoff">Uitloggen</a></li>
          </ul> 
      </div> 
    </div>
    <span class="ms-2 mt-5 me-2 version-number">v{{ version }}</span>
  </nav>
</template>

<script>
import localDB from '../utils/localDB';
import packageInfo from '../../package.json';

export default {
  name: 'NavBar',
  props: {
    formId: {
      type: String,
      required: false,
      default: 'default-form-id'
    },
    isAdmin: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      version: packageInfo.version,
      adminMenuOpen: false,
      navbarCollapsed: true
    };
  },
  mounted() {
    // Sluit dropdown wanneer er ergens anders wordt geklikt
    document.addEventListener('click', this.closeDropdownOnClickOutside);
  },
  beforeUnmount() {
    // Verwijder event listener bij unmount
    document.removeEventListener('click', this.closeDropdownOnClickOutside);
  },
  methods: {
    toggleAdminMenu() {
      this.adminMenuOpen = !this.adminMenuOpen;
    },
    closeDropdownOnClickOutside(event) {
      // Controleer of er buiten de dropdown is geklikt
      if (this.$refs.adminDropdownToggle && !this.$refs.adminDropdownToggle.contains(event.target) && this.adminMenuOpen) {
        this.adminMenuOpen = false;
      }
    },
    logoff() {
      this.$emit('logoff')
    },
    goToPage(page) {
      this.$router.push(page);
      this.adminMenuOpen = false; // Sluit admin dropdown na navigatie
      this.closeNavbar(); // Sluit ook het hoofdmenu
    },
    toggleNavbar() {
      const navbarCollapse = document.getElementById('navbarSupportedContent');
      const collapse = new window.bootstrap.Collapse(navbarCollapse);
      collapse.toggle();
      this.navbarCollapsed = !this.navbarCollapsed;
    },
    closeNavbar() {
      // Sluit het hoofdmenu als het open is
      const navbarCollapse = document.getElementById('navbarSupportedContent');
      if (navbarCollapse && navbarCollapse.classList.contains('show')) {
        const collapse = new window.bootstrap.Collapse(navbarCollapse);
        collapse.hide();
        this.navbarCollapsed = true;
      }
    },
    async testTokenRefresh() {
      // Zet expires_at op 10 seconden vanaf nu
      const expiresAt = Date.now() + 10000;
      await localDB.setItem('expires_at', expiresAt);
      console.log('Token set to expire in 10 seconds');
      
      // Forceer een sync event
      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.ready;
        await registration.sync.register('refresh-token-test');
        console.log('Sync event registered for testing');
      }
      
      this.adminMenuOpen = false; // Sluit admin dropdown na actie
      this.closeNavbar(); // Sluit ook het hoofdmenu
    },
    async checkTokenStatus() {
      const token = await localDB.getItem('token');
      const expiresAt = await localDB.getItem('expires_at');
      const refreshToken = await localDB.getItem('refresh_token');
      
      console.log('Token status check:');
      console.log('- Token aanwezig:', !!token);
      console.log('- Refresh token aanwezig:', !!refreshToken);
      
      if (expiresAt) {
        const now = Date.now();
        const timeLeft = expiresAt - now;
        const minutesLeft = Math.floor(timeLeft / 60000);
        const secondsLeft = Math.floor((timeLeft % 60000) / 1000);
        
        console.log(`- Token verloopt op: ${new Date(expiresAt).toLocaleString()}`);
        console.log(`- Tijd tot verlopen: ${minutesLeft}m ${secondsLeft}s`);
        console.log(`- Token status: ${timeLeft > 0 ? 'Geldig' : 'Verlopen'}`);
        
        // Forceer een sync event als het token bijna verlopen is
        if (timeLeft < 60000) {
          console.log('Token verloopt binnen 1 minuut, forceer refresh...');
          this.testTokenRefresh();
        }
      } else {
        console.log('- Geen vervaldatum gevonden voor token');
      }
      
      this.adminMenuOpen = false; // Sluit admin dropdown na actie
      this.closeNavbar(); // Sluit ook het hoofdmenu
    }
  }
}
</script>

<style scoped>
.version-number {
  font-size: 10px;   
  color: rgba(0, 0, 0, 0.3); 
}
.nav-item .nav-link, .dropdown-item {
  cursor: pointer;
}

/* Custom dropdown styling */
.custom-dropdown {
  position: relative;
}

/* Dropdown toggle styling */
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.custom-dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

/* Mobile styling voor dropdown */
@media (max-width: 991.98px) {
  .custom-dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    padding-left: 1rem;
  }
  
  .dropdown-item {
    padding: 0.5rem 0;
  }
  
  .dropdown-divider {
    border-top-color: rgba(0, 0, 0, 0.1);
  }
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
</style>
