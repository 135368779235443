<template>
  <div class="container-fluid p-0 m-0">
    <NavBar class="mb-0" :formId="formId" :isAdmin="isAdmin" @logoff="logoff" />
    <div class="sticky-tabs shadow-sm">
      <PageTabs v-if="user" :isAdmin="isAdmin" @switchPage="switchPage" :currentPage="currentPage" />
    </div>
    <div class="container main">
      <router-view :user="user" @showToast="showToast" />
    </div>
    <div id="liveToast" :class="toastColor" class="toast toast-container-top-left align-items-center text-white border-0 ms-4" role="alert"
      aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          {{ toastMessage }}
        </div>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
          aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import PageTabs from './components/PageTabs.vue'
import ServiceFactory from './services/ServiceFactory'
import { watch } from 'vue'
import localDB from './utils/localDB';
import api from './services/axiosConfig';
import { getItem } from '@/utils/localDB';
import serviceWorkerService from './services/ServiceWorkerService';

export default {
  name: 'App',
  components: {
    NavBar, PageTabs
  },
  data() {
    return {
      formId: 'app-form',
      currentPage: 'orders',
      user: null,
      toastMessage: '',
      toastColor: 'bg-primary',
      oAuthService: null,
      orderService: null,
      invoiceService: null,
      customerService: null,
      isAdmin: false
    }
  },
  watch: {
    // Update de geselecteerde tab als de route verandert
    $route(to) {
      this.currentPage = to.name;
    }
  },
  methods: {
    showToast(message) {
      const color = message[1]
      this.toastColor = color ? color : 'bg-primary';
      this.toastMessage = message[0]
      this.$nextTick(() => {
        const toastLiveExample = document.getElementById('liveToast')
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastLiveExample)
        toastBootstrap.show()
      })

    },
    switchPage(pageName) {
      switch (pageName) {
        case 'orders':
          this.$router.push('/orders');
          break;
        case 'orderbevestigingen':
          this.$router.push('/orderBevestigingen')
          break;
        case 'invoices':
          this.$router.push('/invoices')
          break;
        case 'customers':
          this.$router.push('/customers')
          break;
        default:
          throw new Error(`can't find pageName: ${pageName}`)
      }
      this.currentPage = pageName
    },
    async loadCurrentPage() {
      const currentPageStorage = await localDB.getItem('currentPage');
      if (currentPageStorage) {
        this.currentPage = JSON.parse(currentPageStorage);
      }
      this.switchPage(this.currentPage)

      watch(() => this.currentPage, async (newValue) => {
        await localDB.setItem('currentPage', JSON.stringify(newValue));
      }, { deep: true });
    },
    async retrieveAccessToken(token, refresh = false) {
      let data = null
      if(!refresh) {
        data = await this.oAuthService.sendAuthCodeToServer(token)
      } else {
        data = await this.oAuthService.refreshAccessToken(token)
      }
      if(!data) {
        this.showToast([`Failed to ${refresh ? 'refresh token' : 'login to Azure authorisation server'}`, 'bg-danger'])
        return
      }
      console.log('JWT dataWithToken: ', data)
      this.user = { token: data.token, refresh_token: data.refresh_token }

      // Sla de nieuwe token op in localDB
      await localDB.setItem('token', data.token);
      await localDB.setItem('refresh_token', data.refresh_token);
      
      // Update de headers van de api instantie
      api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;

      // Na het opslaan van de token
      await serviceWorkerService.registerTokenRefreshSync(data.token, data.refresh_token);
    },
    invalidateToken() {
      localDB.removeItem('token');
      localDB.removeItem('refresh_token');
      localDB.removeItem('expires_at');
      this.user = null
    },
    registerSyncEvent() {
      if ('serviceWorker' in navigator && 'SyncManager' in window) {
        navigator.serviceWorker.ready.then(registration => {
          return registration.sync.register('refresh-token');
        }).then(() => {
          console.log('Sync event registered for token refresh');
        }).catch(error => {
          console.error('Sync registration failed:', error);
        });
      } else {
        console.info('Sync event registration failed: Service Worker or SyncManager not supported');
      }
    },
    logoff() {
      this.$router.push('/login')
      this.user = null
      localDB.removeItem('token');
      localDB.removeItem('refresh_token');
      localDB.removeItem('expires_at');
    },
    setSelectedTabFromRoute() {
      const route = this.$route; // Huidige route object
      if(route.path === '/') {
        this.currentPage = 'orders'
        return
      } else if(route.path) {
        this.currentPage = route.path.substring(1); // Gebruik de path van de route als geselecteerde tab
      }
    },
    async checkAdminStatus() {
      const token = getItem('token');
      if (token) {
        try {
          const isAdmin = await this.oAuthService.checkAdminStatus(token);
          this.isAdmin = isAdmin;
        } catch (error) {
          console.error('Error checking admin status:', error);
          this.isAdmin = false;
        }
      }
    },
  },

  created() {
    this.orderService = ServiceFactory.getService('OrderService')
    this.invoiceService = ServiceFactory.getService('InvoiceService')
    this.customerService = ServiceFactory.getService('CustomerService')
    this.oAuthService = ServiceFactory.getService('OAuth2AzureService')
    this.orderService.addEventListener('unauthorized', () => this.$router.push('/login'))
    this.invoiceService.addEventListener('unauthorized', () => this.$router.push('/login'))
    this.customerService.addEventListener('unauthorized', () => this.$router.push('/login'))
    this.orderService.addEventListener('orderLoadedFailed', (event) => {
      this.showToast([event.detail.message, 'bg-danger'])
    })
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    setTimeout(this.setSelectedTabFromRoute, 200)

    if (code) {
      await this.retrieveAccessToken(code)
      this.$router.push('/orders')
      setTimeout(this.setSelectedTabFromRoute, 200)
      //start the sync event for token refresh in de the Service Worker
      this.registerSyncEvent();
    } else {
      const token = await localDB.getItem('token');
      const refresh_token = await localDB.getItem('refresh_token');
      if (token) {
        this.user = { token, refresh_token }
        //start the sync event for token refresh in de the Service Worker
        this.registerSyncEvent();
      } else {
        this.$router.push('/login')
      }
    }
    setTimeout(this.checkAdminStatus, 1000);
  }
}
</script>

<style>
body {
  /* ivm met fixed-top navbar */
  padding-top: 130px;
}

.sticky-tabs {
  position: fixed;
  top: 76px; /* Hoogte van navbar */
  left: 0;
  right: 0;
  z-index: 1000;
  background: white;
  padding: 0.25rem 0;
}


@media (max-width: 991.98px) {
  body {
    /* Meer padding voor mobiel om ruimte te maken voor het uitgeklapte menu */
    padding-top: 180px;
  }
  
  .sticky-tabs {
    top: 125px; 
  }
  
  /* Wanneer het menu is uitgeklapt, pas de padding aan */
  body.menu-expanded {
    padding-top: 300px; /* Meer ruimte voor uitgeklapt menu */
  }
}

.toast-container-top-left {
  position: fixed;
  top: 5rem;
  left: 1rem;
  z-index: 1055;
}

.custom-purple-btn {
  background-color: purple;
  color: white;
}
.custom-purple-btn:hover {
  background-color: darkpurple;
}
</style>
