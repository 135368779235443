import api from './axiosConfig';

class OAuth2AzureService {

  credentials = {
    tenant: process.env.VUE_APP_TENANT_ID,
    app_id: process.env.VUE_APP_APP_ID,
    redirect_uri: `${this.rootUrl()}/orders`,
    scope: "api://9e625485-e41b-4c2a-8e50-a1c46d4a75e4/Accounts.Lezen",
    state: process.env.VUE_APP_OAUTH_STATE
  }

  url(credentials) {
    console.log("process.env :", process.env);
    console.log("import.meta.env ENV:", import.meta.env);
    console.log('credentials = ', credentials);
    return `https://login.microsoftonline.com/${credentials.tenant}/oauth2/v2.0/authorize?
      client_id=${credentials.app_id}&
      response_type=code&
      redirect_uri=${credentials.redirect_uri}&
      scope=${credentials.scope}&
      response_mode=query&
      state=${credentials.state}&
      prompt=login`
  }

  rootUrl() {
    // if (process.env.NODE_ENV === 'development') {
    //   return process.env.VUE_APP_API_URL || `${window.location.protocol}//${window.location.host}`;
    // }
    return `${window.location.protocol}//${window.location.host}`;
  }

  redirectToAzureLogin() {
    window.location.href = this.url(this.credentials);
  }

  async sendAuthCodeToServer(code) {
    try {
      const response = await api.post('/api/authorize', { code: code })
      console.log(`oAuth2AzureService=>sendAuthCodeToServer(): `, response.data)
      if (response.data.success) {
        return response.data
      } else {
        console.warn(`oAuth2AzureService=>sendAuthCodeToServer(): No success in response data`, response.data);
        return null
      }
    } catch (error) {
      console.log(`oAuth2AzureService=>sendAuthCodeToServer(): `, error.response?.data?.message, error)
    }
  }

  async refreshAccessToken(refreshToken) {
    try {
      const response = await api.post('/api/authorize/refresh', { refresh_token: refreshToken })

      if (response.data.success) {
        console.log(`oAuth2AzureService=>refreshAccessToken(): `, response.data)
        return response.data
      } else {
        return null
      }
    } catch (error) {
      console.log(`oAuth2AzureService=>refreshAccessToken(): `, error.response?.data?.message, error)
    }
  }

  async checkAdminStatus(token) {
    try {
      const response = await api.get('/api/authorize/check-admin', {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data.isAdmin;
    } catch (error) {
      console.error('Error checking admin status:', error);
      return false;
    }
  }

}

export default OAuth2AzureService;
