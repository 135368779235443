import localDB from '../utils/localDB';

class ServiceWorkerService {
  constructor() {
    this.registration = null;
    this.initialized = false;
    this.apiUrl = process.env.VUE_APP_API_URL ||
      window.location.origin;
    this.pendingMessages = [];
  }

  async initialize() {
    if (this.initialized || !('serviceWorker' in navigator)) {
      return;
    }

    try {
      // Registreer de service worker
      this.registration = await navigator.serviceWorker.register('/service-worker.js');
      console.log('Service Worker geregistreerd met scope:', this.registration.scope);

      // Forceer activatie van de service worker
      if (this.registration.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }

      // Luister naar updates van de service worker
      this.registration.addEventListener('updatefound', () => {
        const newWorker = this.registration.installing;
        console.log('Service Worker update gevonden, installeren...');

        newWorker.addEventListener('statechange', () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            console.log('Nieuwe Service Worker geïnstalleerd, activeren...');
            newWorker.postMessage({ type: 'SKIP_WAITING' });
          }
        });
      });

      // Luister naar controller change events
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log('Nieuwe Service Worker actief geworden');
        this.sendPendingMessages();
      });

      // Wacht tot de service worker klaar is
      if (navigator.serviceWorker.controller) {
        // Service worker is al actief
        this.configureServiceWorker();
      } else {
        // Service worker is nog niet actief, wacht op 'controllerchange' event
        console.log('Service Worker is nog niet actief, wacht op activatie...');

        // Sla de API URL op om later te verzenden
        this.addPendingMessage({
          type: 'API_CONFIG',
          apiUrl: this.apiUrl
        });

        // Gebruik een Promise om te wachten tot de service worker actief is
        await new Promise(resolve => {
          // Als de service worker al actief is
          if (navigator.serviceWorker.controller) {
            resolve();
            return;
          }

          // Wacht op het controllerchange event
          const onControllerChange = () => {
            navigator.serviceWorker.removeEventListener('controllerchange', onControllerChange);
            resolve();
          };

          navigator.serviceWorker.addEventListener('controllerchange', onControllerChange);

          // Timeout voor het geval het event niet wordt afgevuurd
          setTimeout(resolve, 5000);
        });

        // Nu zou de service worker actief moeten zijn
        this.sendPendingMessages();
      }

      this.initialized = true;
    } catch (error) {
      console.error('Service Worker registratie mislukt:', error);
    }
  }

  // Voeg een bericht toe aan de wachtrij
  addPendingMessage(message) {
    this.pendingMessages.push(message);
  }

  // Verzend alle berichten in de wachtrij
  sendPendingMessages() {
    if (navigator.serviceWorker.controller && this.pendingMessages.length > 0) {
      console.log(`Verzenden van ${this.pendingMessages.length} wachtende berichten naar Service Worker`);

      this.pendingMessages.forEach(message => {
        navigator.serviceWorker.controller.postMessage(message);
      });

      this.pendingMessages = [];
    }
  }

  configureServiceWorker() {
    if (!navigator.serviceWorker.controller) {
      console.warn('Service Worker is nog niet actief, bericht wordt in wachtrij geplaatst');
      this.addPendingMessage({
        type: 'API_CONFIG',
        apiUrl: this.apiUrl
      });
      return;
    }

    navigator.serviceWorker.controller.postMessage({
      type: 'API_CONFIG',
      apiUrl: this.apiUrl
    });
    console.log('API URL verzonden naar Service Worker:', this.apiUrl);
  }

  async registerTokenRefreshSync(token, refreshToken) {
    if (!this.registration || !token || !refreshToken) {
      return;
    }

    // Sla de tokens op in IndexedDB
    await localDB.setItem('token', token);
    await localDB.setItem('refresh_token', refreshToken);

    if ('SyncManager' in window) {
      try {
        await this.registration.sync.register('refresh-token');
        console.log('Sync event geregistreerd voor token refresh');
      } catch (error) {
        console.error('Sync registratie mislukt:', error);
      }
    } else {
      console.info('Sync event registratie mislukt: SyncManager niet ondersteund');
    }
  }
}

// Singleton instantie
const serviceWorkerService = new ServiceWorkerService();
export default serviceWorkerService;
